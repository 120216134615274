.how-to-start {
  margin-top: -40px;
  background-color: #fff;
  border-radius: 40px;
  overflow: hidden;
  z-index: 10;
}
.how-to-start--right {
  margin: 80px auto 40px;
  width: 85%;
}
.how-to-start__step {
  position: relative;
  margin-bottom: 16px;
  padding: 20px 24px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 12px 30px 0px rgba(0, 0, 0, 0.08);
}
.how-to-start__step-number {
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  min-height: 32px;
  border: 1px solid #ff234f;
  border-radius: 50%;
  color: #ff234f;
}
.how-to-start__step-number--icon {
  background-color: #ff234f;
}
.how-to-start__step-arrow {
  position: absolute;
  top: 50%;
}
.how-to-start__step-arrow--left {
  left: -34px;
}
.how-to-start__step-arrow--right {
  right: -34px;
}
.how-to-start__logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 30px;
}

.our-solutions {
  margin-top: -40px;
  padding: 120px 16px 40px;
  border-radius: 0px 0px 40px 40px;
}

.inventory--left {
  margin-bottom: 4rem;
}
.inventory--right {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}
.inventory--right iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ad-opti {
  border-radius: 0px 0px 40px 40px;
  overflow: hidden;
  z-index: 10;
}
.ad-opti--right {
  display: none;
}
.ad-opti .bg-shape {
  position: absolute;
  bottom: -20%;
  left: -10%;
  z-index: -1;
}

.agora {
  padding: 80px 16px;
}
.agora__checkpoints li {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 16px;
  font-weight: 700;
}
.agora--right {
  margin: 4rem 0;
  position: relative;
  overflow-x: hidden;
}
.agora--right img {
  max-width: 100%;
  height: auto;
}
.agora__illu {
  position: absolute;
  max-width: 50%;
}
.agora__illu--one {
  top: 60px;
  left: 20px;
  max-width: 80%;
}
.agora__illu--two {
  top: 0;
  left: 50px;
}
.agora__illu--three {
  top: 130px;
  right: 0;
}
.agora__illu--four {
  top: 165px;
  left: 0;
}
.agora--bottom {
  padding: 30px;
  text-align: center;
  background-color: #f6f6f6;
  border-radius: 60px;
}
.agora--bottom p {
  margin-bottom: 1.6rem;
}

.cases {
  padding: 40px 0 80px 16px;
  border-radius: 40px;
  z-index: 10;
}
.cases__slider {
  padding: 40px 0;
}
.cases__slide {
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0px 12px 30px 0px rgba(0, 0, 0, 0.08);
}
.cases__slide-content {
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
}
.cases__slide-img {
  margin-bottom: 2.4rem;
}
.cases__slide-title {
  margin-bottom: 1.6rem;
}
.cases__slide-link {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 700;
  color: #ff234f;
}

.contact-us {
  margin-top: -40px;
  padding: 120px 16px 80px;
}

@media (min-width: 768px) {
  .our-solutions {
    padding: 120px 60px 40px;
  }
  .agora {
    padding: 80px 60px;
  }
  .cases {
    padding: 85px 0 60px 60px;
  }
}
@media (min-width: 992px) {
  .how-to-start {
    margin-top: -60px;
    display: grid;
    border-radius: 60px;
  }
  .how-to-start--left {
    margin: auto;
    grid-column: 1/2;
  }
  .how-to-start--right {
    margin: 0 0 0 auto;
    grid-column: 2/3;
  }
  .how-to-start__logos {
    margin-top: 6.4rem;
    grid-column: 1/3;
    grid-row: 2/3;
    justify-content: space-between;
  }
  .our-solutions {
    margin-top: -60px;
    padding: 160px 80px 100px;
    border-radius: 0px 0px 60px 60px;
  }
  .our-solutions__products {
    display: grid;
    column-gap: 16px;
    row-gap: 40px;
  }
  .our-solutions .card {
    margin: 0;
  }
  .our-solutions .card:nth-child(1) {
    grid-column: 1/3;
  }
  .our-solutions .card:nth-child(2) {
    grid-column: 3/5;
  }
  .our-solutions .card:nth-child(3) {
    grid-column: 5/7;
  }
  .our-solutions .card:nth-child(4) {
    grid-column: 2/4;
  }
  .our-solutions .card:nth-child(5) {
    grid-column: 4/6;
  }
  .inventory {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 70px;
  }
  .inventory--left {
    margin-bottom: 0;
  }
  .inventory--left, .inventory--right {
    margin: auto 0;
  }
  .ad-opti {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-radius: 0px 0px 60px 60px;
  }
  .ad-opti--left {
    grid-column: 1/2;
  }
  .ad-opti--right {
    position: relative;
    display: block;
    grid-column: 2/3;
  }
  .ad-opti__illu {
    position: absolute;
  }
  .ad-opti__illu--one {
    top: 68px;
    right: -150px;
  }
  .ad-opti__illu--two {
    top: 230px;
    left: 60px;
  }
  .agora {
    padding: 100px 80px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .agora--left {
    margin: auto 0;
    grid-column: 1/2;
  }
  .agora--left > p {
    margin-bottom: 4rem;
  }
  .agora--right {
    margin: 0;
    grid-column: 2/3;
  }
  .agora__illu--one {
    top: -30px;
    left: unset;
    right: 18px;
  }
  .agora__illu--two {
    top: -110px;
    left: 200px;
  }
  .agora__illu--three {
    top: 100px;
    right: -30px;
  }
  .agora__illu--four {
    top: 160px;
    left: 80px;
  }
  .agora--bottom {
    margin-top: 110px;
    grid-column: 1/3;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .agora--bottom p {
    margin-bottom: 0;
  }
  .cases {
    padding: 85px 0 80px 80px;
    border-radius: 60px;
  }
  .cases .section__title {
    margin: 0;
  }
  .cases .keen-slider__nav {
    top: 84px;
  }
  .contact-us {
    margin-top: -60px;
    padding: 180px 80px 120px;
  }
}
@media (min-width: 1200px) {
  .our-solutions {
    padding: 180px 100px 120px;
  }
  .agora {
    padding: 100px;
  }
  .cases {
    padding: 85px 0 80px 100px;
  }
  .contact-us {
    padding: 180px 100px 120px;
  }
}
@media (min-width: 1400px) {
  .our-solutions {
    padding: 180px 120px 120px;
  }
  .agora {
    padding: 100px 120px;
  }
  .cases {
    padding: 85px 0 80px 120px;
  }
  .contact-us {
    padding: 180px 120px 120px;
  }
}