@use './variables' as *;

.how-to-start {
	margin-top: -40px;
	background-color: #fff;
	border-radius: 40px;
	overflow: hidden;
	z-index: 10;

	&--right {
		margin: 80px auto 40px;
		width: 85%;
	}

	&__step {
		position: relative;
		margin-bottom: 16px;
		padding: 20px 24px;
		display: flex;
		align-items: center;
		background-color: #fff;
		border-radius: 12px;
		box-shadow: $mainboxshadow;
	}

	&__step-number {
		margin-right: 16px;
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: 32px;
		min-height: 32px;
		border: 1px solid $uiprimary500;
		border-radius: 50%;
		color: $uiprimary500;

		&--icon {
			background-color: $uiprimary500;
		}
	}

	&__step-arrow {
		position: absolute;
		top: 50%;

		&--left {
			left: -34px;
		}

		&--right {
			right: -34px;
		}
	}

	&__logos {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-evenly;
		align-items: center;
		gap: 30px;
	}
}

.our-solutions {
	margin-top: -40px;
	padding: 120px 16px 40px;
	border-radius: 0px 0px 40px 40px;
}

.inventory {

	&--left {
		margin-bottom: 4rem;
	}

	&--right {
		position: relative;
		padding-bottom: 56.25%;
		height: 0;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}

.ad-opti {
	border-radius: 0px 0px 40px 40px;
	overflow: hidden;
	z-index: 10;

	&--right {
		display: none;
	}

	.bg-shape {
		position: absolute;
		bottom: -20%;
		left: -10%;
		z-index: -1;
	}
}

.agora {
	padding: 80px 16px;

	&__checkpoints li {
		margin-bottom: 8px;
		display: flex;
		align-items: center;
		gap: 16px;
		font-weight: 700;
	}

	&--right {
		margin: 4rem 0;
		position: relative;
		overflow-x: hidden;

		img {
			max-width: 100%;
			height: auto;
		}
	}

	&__illu {
		position: absolute;
		max-width: 50%;

		&--one {
			top: 60px;
			left: 20px;
			max-width: 80%;
		}

		&--two {
			top: 0;
			left: 50px;
		}

		&--three {
			top: 130px;
			right: 0;
		}

		&--four {
			top: 165px;
			left: 0;
		}
	}

	&--bottom {
		padding: 30px;
		text-align: center;
		background-color: $uigray100;
		border-radius: 60px;

		& p {
			margin-bottom: 1.6rem;
		}
	}
}

.cases {
	padding: 40px 0 80px 16px;
	border-radius: 40px;
	z-index: 10;

	&__slider {
		padding: 40px 0;
	}

	&__slide {
		border-radius: 20px;
		background-color: #fff;
		box-shadow: $mainboxshadow;
	}

	&__slide-content {
		padding: 32px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		min-height: 100%;
	}

	&__slide-img {
		margin-bottom: 2.4rem;
	}

	&__slide-title {
		margin-bottom: 1.6rem;
	}

	&__slide-link {
		display: flex;
		align-items: center;
		gap: 8px;
		font-weight: 700;
		color: $uiprimary500;
	}
}

.contact-us {
	margin-top: -40px;
	padding: 120px 16px 80px;
}

@media (min-width: 768px) {
	.our-solutions {
		padding: 120px 60px 40px;
	}

	.agora {
		padding: 80px 60px;
	}

	.cases {
		padding: 85px 0 60px 60px;
	}
}

@media (min-width: 992px) {
	.how-to-start {
		margin-top: -60px;
		display: grid;
		border-radius: 60px;

		&--left {
			margin: auto;
			grid-column: 1/2;
		}

		&--right {
            margin: 0 0 0 auto;
			grid-column: 2/3;
		}

		&__logos {
			margin-top: 6.4rem;
			grid-column: 1/3;
			grid-row: 2/3;
			justify-content: space-between;
		}
	}

	.our-solutions {
		margin-top: -60px;
		padding: 160px 80px 100px;
		border-radius: 0px 0px 60px 60px;

		&__products {
			display: grid;
			column-gap: 16px;
			row-gap: 40px;
		}

		.card {
			margin: 0;

			&:nth-child(1) {
				grid-column: 1/3;
			}
			&:nth-child(2) {
				grid-column: 3/5;
			}
			&:nth-child(3) {
				grid-column: 5/7;
			}
			&:nth-child(4) {
				grid-column: 2/4;
			}
			&:nth-child(5) {
				grid-column: 4/6;
			}
		}
	}

	.inventory {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 70px;

		&--left {
			margin-bottom: 0;
		}

		&--left, &--right {
			margin: auto 0;
		}
	}

	.ad-opti {
		display: grid;
		grid-template-columns: 1fr 1fr;
		border-radius: 0px 0px 60px 60px;

		&--left {
			grid-column: 1/2;
		}

		&--right {
			position: relative;
			display: block;
			grid-column: 2/3;
		}

		&__illu {
			position: absolute;

			&--one {
				top: 68px;
				right: -150px;
			}

			&--two {
				top: 230px;
				left: 60px;
			}
		}
	}

	.agora {
		padding: 100px 80px;
		display: grid;
		grid-template-columns: 1fr 1fr;

		&--left {
			margin: auto 0;
			grid-column: 1/2;

			& > p {
				margin-bottom: 4rem;
			}
		}

		&--right {
			margin: 0;
			grid-column: 2/3;
		}

		&__illu {
			&--one {
				top: -30px;
				left: unset;
				right: 18px;
			}

			&--two {
				top: -110px;
				left: 200px;
			}

			&--three {
				top: 100px;
				right: -30px;
			}

			&--four {
				top: 160px;
				left: 80px;
			}
		}

		&--bottom {
			margin-top: 110px;
			grid-column: 1/3;
			display: flex;
			justify-content: space-evenly;
			align-items: center;

			& p {
				margin-bottom: 0;
			}
		}
	}

	.cases {
		padding: 85px 0 80px 80px;
		border-radius: 60px;

		.section__title {
			margin: 0;
		}

		.keen-slider__nav {
			top: 84px;
		}
	}

	.contact-us {
		margin-top: -60px;
		padding: 180px 80px 120px;
	}
}

@media (min-width: 1200px) {
	.our-solutions {
		padding: 180px 100px 120px;
	}

	.agora {
		padding: 100px;
	}

	.cases {
		padding: 85px 0 80px 100px;
	}

	.contact-us {
		padding: 180px 100px 120px;
	}	
}

@media (min-width: 1400px) {
	.our-solutions {
		padding: 180px 120px 120px;
	}

	.agora {
		padding: 100px 120px;
	}

	.cases {
		padding: 85px 0 80px 120px;
	}

	.contact-us {
		padding: 180px 120px 120px;
	}	
}
